import { DatosUsuario } from './datos-usuario';
import { DatosIdioma } from './datos-idioma';
import { DatosRegistro } from './datos-registro';
import { DatosVersion } from './datos-version';

export class CuentaUsuario {
    datosUsuario: DatosUsuario;
    datosIdioma: DatosIdioma;
    datosRegistro: DatosRegistro;
    datosVersion: DatosVersion

  constructor() {

  }  
}
