import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { ConexionBase, Retorno, ERROR_HASH_CODE, ERROR_HASH_DESC } from '../../clases/varios/conexion-base';

import { ConfigAppService } from '../config-app/config-app.service';

import { CifradorService } from '../cifrador/cifrador.service';
import { MiSesionService } from '../mi-sesion/mi-sesion.service';
import { HttpService }  from '../http/http.service';
import { UtilidadesService }  from '../utils/utilidades.service';
import { ExcepcionService }  from '../excepcion/excepcion.service';
import { ImagenService, ImagenesEntrada, TIPO_CONSULTA_CONTACTOS }  from '../imagenes/imagen.service';


export class LoginEntrada {
  versionActual: number;
  idioma: string;
  masterKey: string;
  registrationCode: string;   // Id para el teléfono (Registration Code -Google Cloud, debe guardarse en secreto). Aqui se envía sólo en plan informativo. De momento, no se guarda en BB.DD.(sólo al registrar)

  deviceId: string;
  versionSO: string;
  packageName: string;
  versionCode: string;
  versionNumber: string;

  hash: string;
}

export class LoginSalida {
  idSesion: string;
  admin: boolean;
  codigoAccion: string;
  descripcionAccion: string;
  precio: string;
  monedaPago: string;
  pagoObligado: boolean;
  encuestasGratis: number;
  gruposGratis: number;
  publicidadBanner: boolean;
  publicidadInterstitial: boolean;
  publicidadVideo: boolean;
  publicidadTestingMode: boolean;
  fechaUltimoPago: string;
  fechaUltimaContribucion: string;
  fechaInicioPublicidad: string;
  activarPagoPaypal: boolean;
  activarTarjetaPaypal: boolean;
  activarPagoPorStoreEncuesta: boolean;
  activarPagoPorStoreGrupo: boolean;
  productIDContribucion: string;
  productIDCandidatura: string;
  productIDVotacion: string;
  productIDGrupo: string;
  productIDEncuesta: string;
  productIDPromocionEncuesta: string;
  productIDDescargaPDF: string;
  fechaAvatar: string;
  aliasUsuario: string;
  idImagenUsuario: string; // Incluye instancia, si es necesario.
  fechaImagenUsuario: string;
  pushIOS: boolean;
  pushIOSsandbox: boolean;
  tutorial_es: string;
  tutorial_en: string;
  tutorial_exclusiones: string;
  telefono_adaptado: string;
  videos: any;
  segundosVerPublicidad: number;
  mesesSinPublicidad: number;
  soloPublicidadGoogleAutomatica: boolean;
  publicidadMixta: boolean;
  retorno: Retorno;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ConexionBase {
  private urlRelativa = '/registro/realizaLoginApp.php';  // URL to web api
  public datosSalidaLogin: LoginSalida;
  public loginEntrada: LoginEntrada;

  constructor(private httpService: HttpService,
              private cifrador: CifradorService,
              private miSesion: MiSesionService,
              private imagenService: ImagenService,
              private utilidades: UtilidadesService,
              private excepcion: ExcepcionService,
              public cfgApp: ConfigAppService) {
    super(cfgApp);
  }

  ejecutaLogin() {
    this.miSesion.datosRegistroListos.then(res => {
      this.miSesion.datosIdiomaListos.then(res => {
        this.miSesion.datosVersionListos.then(res => {
          // Esperamos a que finalicen todos los procesos asíncronos necesarios.
          this.loginEntrada = new LoginEntrada();

          this.loginEntrada.masterKey = this.miSesion.registro.masterKey;
          this.loginEntrada.versionActual = this.miSesion.version.versionActual;
          this.loginEntrada.idioma = this.miSesion.idioma.idioma;
          this.loginEntrada.registrationCode = this.miSesion.registro.registrationCode;

          this.loginEntrada.deviceId = (this.cfgApp.getDeviceId()) ? this.cfgApp.getDeviceId(): this.cfgApp.SIM_DEVICE_ID_MOCK;
          this.loginEntrada.versionSO = (this.cfgApp.getVersionSO()) ? this.cfgApp.getVersionSO(): this.cfgApp.VERSION_SO_MOCK;    
          this.loginEntrada.packageName = this.miSesion.version.packageName;
          this.loginEntrada.versionCode = this.miSesion.version.versionCode;
          this.loginEntrada.versionNumber = this.miSesion.version.versionNumber;

          this.loginEntrada.hash = this.cifrador.getHashKey();

          this.postLogin(this.loginEntrada).then(res =>  {
            this.datosSalidaLogin = res;
            if (this.datosSalidaLogin.retorno.codigo == this.cfgApp.RESPUESTA_CORRECTA) {
              this.miSesion.idSesion = this.datosSalidaLogin.idSesion;
              this.miSesion.usuario.admin = this.datosSalidaLogin.admin;
              // SI RECIBIMOS ALGÚN MENSAJE, LO COMUNICAMOS AL USUARIO.
              if (this.datosSalidaLogin.codigoAccion != this.cfgApp.RESPUESTA_CORRECTA) {
                if (this.datosSalidaLogin.codigoAccion == "E1034") {
                  // Actualización de versión.
                  if (!this.miSesion.version.numActualizarVersionRecibidos) this.miSesion.version.numActualizarVersionRecibidos = 0; // Por si no está definido.
                  if (this.miSesion.version.numActualizarVersionRecibidos >= 1) {
                    this.miSesion.version.codigoAccion = this.datosSalidaLogin.codigoAccion;
                    this.miSesion.version.mensajeAccion = this.datosSalidaLogin.descripcionAccion;
                    this.miSesion.version.numActualizarVersionRecibidos = 0;
                  } else {
                    // El mensaje de actualizar lo mostramos tras el segundo login para evitar mostrar el mensaje nada más actualizar la version.
                    this.miSesion.version.numActualizarVersionRecibidos = this.miSesion.version.numActualizarVersionRecibidos + 1;
                  }
                } else {
                  this.miSesion.version.numActualizarVersionRecibidos = 0;
                  this.miSesion.version.codigoAccion = this.datosSalidaLogin.codigoAccion;
                  this.miSesion.version.mensajeAccion = this.datosSalidaLogin.descripcionAccion;
                }
              } else {
                this.miSesion.version.codigoAccion = "";
                this.miSesion.version.mensajeAccion = "";
              }
              this.miSesion.usuario.precio = this.datosSalidaLogin.precio;
              this.miSesion.usuario.monedaPago = this.datosSalidaLogin.monedaPago;

              this.miSesion.usuario.pagoObligado = this.datosSalidaLogin.pagoObligado;
              this.miSesion.usuario.encuestasGratis = this.datosSalidaLogin.encuestasGratis;
              this.miSesion.usuario.gruposGratis = this.datosSalidaLogin.gruposGratis;

              this.miSesion.usuario.publicidadBanner = this.datosSalidaLogin.publicidadBanner;
              this.miSesion.usuario.publicidadInterstitial = this.datosSalidaLogin.publicidadInterstitial;
              this.miSesion.usuario.publicidadVideo = this.datosSalidaLogin.publicidadVideo;
              this.miSesion.usuario.publicidadTestingMode = this.datosSalidaLogin.publicidadTestingMode;
              this.miSesion.usuario.fechaInicioPublicidad = moment(moment.utc(this.datosSalidaLogin.fechaInicioPublicidad + " +00:00", "YYYY-MM-DD HH:mm ZZ").format()).format("YYYY-MM-DD HH:mm");

              // Las fechas las convertimos a fechas locales (en el servidor están en UTC).
              this.miSesion.usuario.fechaUltimoPago = moment(moment.utc(this.datosSalidaLogin.fechaUltimoPago + " +00:00", "YYYY-MM-DD HH:mm ZZ").format()).format("YYYY-MM-DD HH:mm");
              this.miSesion.usuario.fechaUltimaContribucion = moment(moment.utc(this.datosSalidaLogin.fechaUltimaContribucion + " +00:00", "YYYY-MM-DD HH:mm ZZ").format()).format("YYYY-MM-DD HH:mm");

              // Control de pagos.
              this.miSesion.usuario.activarPagoPaypal = this.datosSalidaLogin.activarPagoPaypal;
              this.miSesion.usuario.activarTarjetaPaypal = this.datosSalidaLogin.activarTarjetaPaypal;
              this.miSesion.usuario.activarPagoPorStoreEncuesta = this.datosSalidaLogin.activarPagoPorStoreEncuesta;
              this.miSesion.usuario.activarPagoPorStoreGrupo = this.datosSalidaLogin.activarPagoPorStoreGrupo;

              this.miSesion.usuario.productIDContribucion = this.datosSalidaLogin.productIDContribucion;
              this.miSesion.usuario.productIDCandidatura = this.datosSalidaLogin.productIDCandidatura;
              this.miSesion.usuario.productIDVotacion = this.datosSalidaLogin.productIDVotacion;
              this.miSesion.usuario.productIDGrupo = this.datosSalidaLogin.productIDGrupo;
              this.miSesion.usuario.productIDEncuesta = this.datosSalidaLogin.productIDEncuesta;
              this.miSesion.usuario.productIDPromocionEncuesta = this.datosSalidaLogin.productIDPromocionEncuesta;
              this.miSesion.usuario.productIDDescargaPDF = this.datosSalidaLogin.productIDDescargaPDF;

              this.consultaImagenPerfil();
              this.miSesion.usuario.alias = this.datosSalidaLogin.aliasUsuario;

              this.miSesion.usuario.pushIOS = this.datosSalidaLogin.pushIOS;
              this.miSesion.usuario.pushIOSsandbox = this.datosSalidaLogin.pushIOSsandbox;

              this.miSesion.usuario.tutorial_es = this.datosSalidaLogin.tutorial_es;
              this.miSesion.usuario.tutorial_en = this.datosSalidaLogin.tutorial_en;
              this.miSesion.usuario.tutorial_exclusiones = this.datosSalidaLogin.tutorial_exclusiones;

              this.miSesion.usuario.videos = this.datosSalidaLogin.videos;

              this.miSesion.usuario.segundosVerPublicidad = this.datosSalidaLogin.segundosVerPublicidad;
              this.miSesion.usuario.mesesSinPublicidad = this.datosSalidaLogin.mesesSinPublicidad;

              this.miSesion.usuario.soloPublicidadGoogleAutomatica = this.datosSalidaLogin.soloPublicidadGoogleAutomatica;
              this.miSesion.usuario.publicidadMixta = this.datosSalidaLogin.publicidadMixta;

              this.miSesion.guardaDatosSesion();
            } else {
              this.miSesion.version.mensajeAccion = this.utilidades.traduce('ERROR_NUM.' + this.datosSalidaLogin.retorno.codigo);
              this.miSesion.guardaDatosSesion();
            }
          }, err => {
            this.excepcion.comunicaExcepcion(this.constructor.name, "POST Login", err);
            this.utilidades.presentToast(this.utilidades.traduce('ERROR_NUM.E1001'));
          }).catch(this.handleHTTPError);

        });
      });
    });
  }

  public consultaImagenPerfil() {
    if (this.datosSalidaLogin.idImagenUsuario && this.datosSalidaLogin.idImagenUsuario != "") {
      let fechaOnLine2 = moment(this.datosSalidaLogin.fechaImagenUsuario, "YYYY-MM-DD HH:mm:ss")
      let fecha2 = moment((this.miSesion.usuario.fechaImagenUsuario == "") ? "1966-01-01 00:00:00": this.miSesion.usuario.fechaImagenUsuario, "YYYY-MM-DD HH:mm:ss")

      if ((!this.miSesion.usuario.avatar) || (fechaOnLine2.isAfter(fecha2))) {
        let idImagenesPerfil = new Array<string>();

        let imagenesEntradaP = new ImagenesEntrada();
        idImagenesPerfil.push(this.datosSalidaLogin.idImagenUsuario);

        imagenesEntradaP.idSesion = this.miSesion.idSesion;
        imagenesEntradaP.masterKey = this.miSesion.registro.masterKey;
        imagenesEntradaP.versionActual = this.miSesion.version.versionActual;
        imagenesEntradaP.idioma = this.miSesion.idioma.idioma;
        imagenesEntradaP.identificadores = idImagenesPerfil;
        imagenesEntradaP.tipoConsulta = TIPO_CONSULTA_CONTACTOS;
        imagenesEntradaP.hash = this.cifrador.getHashKey();

        this.imagenService.postConsultaImagenes(imagenesEntradaP).then(imagenes => {
          if (imagenes.retorno.codigo == this.cfgApp.RESPUESTA_CORRECTA) {
            if (imagenes.lista && imagenes.lista.length > 0) {
              this.miSesion.usuario.idImagenUsuario = imagenes.lista[0].idImagen;
              this.miSesion.usuario.fechaImagenUsuario = imagenes.lista[0].fechaImagen;
              this.miSesion.usuario.avatar = imagenes.lista[0].imagen;
              this.miSesion.guardaDatosSesion();
            } else {
              this.excepcion.comunicaExcepcion(this.constructor.name, "Imagen Perfil vacía", null);
            }
          } else {
            console.error('EasySanta:: Error 4 ', imagenes.retorno.codigo);
          }
        }, err => {
          this.excepcion.comunicaExcepcion(this.constructor.name, "POST imagen perfil login", err);
        }).catch(this.handleHTTPError);
      }
    } else {
      // No tengo imagen.
      this.miSesion.usuario.idImagenUsuario = this.datosSalidaLogin.idImagenUsuario;
      this.miSesion.usuario.fechaImagenUsuario = this.datosSalidaLogin.fechaImagenUsuario;
    }
  }  

  public postLogin(entrada: LoginEntrada): Promise<LoginSalida> {
    let miHash = this.generaHashConexion(JSON.stringify(entrada));
    entrada.hash = miHash.toString();

    return new Promise(resolve => {
      this.httpService.send(this.getURLAbsoluta(this.urlRelativa), entrada)
        .subscribe(response => {
            // we've got back the raw data, now generate the core schedule data
            // and save the data for later reference
            this.datosSalidaLogin = <LoginSalida> (response.data) ? JSON.parse(response.data): response.body;

            let hash = this.datosSalidaLogin.retorno.hash;
            this.datosSalidaLogin.retorno.hash = this.cifrador.getHashKey(false);
            if (!this.validaHashConexion(JSON.stringify(this.datosSalidaLogin), hash)) {
              this.datosSalidaLogin.retorno.codigo = ERROR_HASH_CODE.toString();
              this.datosSalidaLogin.retorno.descripcion = ERROR_HASH_DESC.toString();
            }

            resolve(this.datosSalidaLogin);
          }, err => {
            resolve(Promise.reject(err.message || err))
          }
        );
    });

  }

  private handleHTTPError(error: any) {
    console.error('Atenea:: Error en HTTP ', error);
    return Promise.reject(error.message || error);
  }  

}