export class Cadena {
  miCadena: string;

  public replaceAll(cadena: string, buscar: string, reemplazar: string) {
    this.miCadena = cadena;
    while (this.miCadena.indexOf(buscar) >= 0) {
      this.miCadena = this.miCadena.replace(buscar, reemplazar);
    }

    return this.miCadena;
  }

  public replaceChar(cadena: string, buscar: number, reemplazar: string) {
    let texto = cadena;
    
    let salida = "";
    for (let i = 0; i < texto.length; i++) {
      if (texto[i].charCodeAt(0) == buscar) {
        salida += reemplazar;
      } else {
        salida += texto[i];
      }
    }

    return salida;
  }  

  public contarPalabras(texto: string) {
    let contador = 0;
    let copia = texto;
    let simbolos = ['.', ',', ':', ';', '<', '>', '=', '-', '_', '´', '{', '}', '`', '[', ']', '*', '¡', '!', '¿', '?', "'", '"', '|', '@', '#', '·', '$', '%', '&', '/', '(', ')'];

    if (texto && (texto != "")) {
      simbolos.forEach(simbolo => {
        copia = this.replaceAll(copia, simbolo, '');
      });
  
      copia = this.replaceAll(copia, "  ", " ").trim();
  
      contador = copia.split(" ").length;
    }

    return contador;
  }
}
