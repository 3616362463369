import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/fromPromise';
import { fromPromise } from 'rxjs/internal/observable/fromPromise'


import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

import { MiSesionService } from '../mi-sesion/mi-sesion.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {


  constructor(public miSesion: MiSesionService,
              public platform: Platform,
              private http: HttpClient,
              public httpNative: HTTP) {
  }

  public send(url: string, entrada: object): Observable<any> {
    
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
      
    if (!this.platform.is('cordova')) {
      return this.http.post(url, JSON.stringify(entrada), {headers: headers, observe: 'response'});
    } else {
      this.httpNative.setDataSerializer("utf8");
      return fromPromise(this.httpNative.post(url, JSON.stringify(entrada), {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }));
    }
  }
}