import { Injectable } from '@angular/core';

import { ConexionBase } from '../../clases/varios/conexion-base';

import { MiSesionService } from '../mi-sesion/mi-sesion.service';
import { HttpService } from '../http/http.service';
import { ConfigAppService } from '../config-app/config-app.service';
import { CifradorService } from '../cifrador/cifrador.service';

export class ExceptionEntrada {
  idSesion: string;
  versionActual: number;
  idioma: string;
  masterKey: string;
  registrationCode: string;   // Id para el teléfono (Registration Code -Google Cloud, debe guardarse en secreto). Aqui se envía sólo en plan informativo. De momento, no se guarda en BB.DD.(sólo al registrar)

  clase: string;
  descripcion: string;
  errorNombre: string;
  errorMensaje: string;
  errorPila: string;

  hash: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExcepcionService extends ConexionBase {
  private urlRelativa = '/registro/comunicaExcepcionApp.php';  // URL to web api
  public datosSalidaExcepcion: any;

  constructor(private httpService: HttpService,
              public cfgApp: ConfigAppService,
              private cifrador: CifradorService,
              private miSesion: MiSesionService) {
    super(cfgApp);
  }

  public comunicaExcepcion(clase: string, descripcion:string, miError: any) {
    // Primeramente guardamos el log.
    if (miError) {
      console.error("Atenea:: " + clase + ":: " + descripcion, miError);
    } else {
      console.error("Atenea:: " + clase + ":: " + descripcion);
    }

    // A continuación lo comunicamos al servidor.
    this.postException(clase, descripcion, miError)
      .then(res => {console.log(res)}, err => {console.log(err)})
      .catch(this.handleHTTPError);
  }

  private calculaTextosError(error: any, entrada: ExceptionEntrada) {
    entrada.errorNombre = ((error && error.name) ? error.name: ((error) ? error: "errorNombre"));
    entrada.errorMensaje = ((error && error.message) ? error.message: ((error) ? error: "errorMensaje"));
    entrada.errorPila = ((error && error.stack) ? error.stack: ((error) ? error: "errorPila"));

  }

  private postException(clase: string, descripcion:string, miError: Error): Promise<any> {
    let entrada = new ExceptionEntrada();
    entrada.idSesion = this.miSesion.idSesion;
    entrada.masterKey = this.miSesion.registro.masterKey;
    entrada.versionActual = this.miSesion.version.versionActual;
    entrada.idioma = this.miSesion.idioma.idioma;
    entrada.registrationCode = this.miSesion.registro.registrationCode;
    if (clase) {
      entrada.clase = clase;
    } else {
      entrada.clase = "Clase no definida";
    }
    entrada.descripcion = descripcion;

    this.calculaTextosError(miError, entrada);

    entrada.hash = this.cifrador.getHashKey();

    let miHash = this.generaHashConexion(JSON.stringify(entrada));
    entrada.hash = miHash.toString();

    return new Promise(resolve => {
      this.httpService.send(this.getURLAbsoluta(this.urlRelativa), entrada)
        .subscribe(data => {
            resolve(data);
          }, err => {
            resolve(Promise.reject(err.message || err))
          }
        );
    });

  }

  private handleHTTPError(error: any) {
    // ESTE ES EL ÚNICO PUNTO DONDE NO SE PUEDE COMUNICAR EL ERROR AL SERVIDOR.
    console.error('Atenea:: Comunicación del error al servidor: ', error);
    return Promise.reject(error.message || error);
  }

}