import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastController, LoadingController  } from '@ionic/angular';

import { ConfigAppService } from '../../servicios/config-app/config-app.service';
import { MiSesionService } from '../../servicios/mi-sesion/mi-sesion.service';
import { TiposDeConsulta, FiltrosEncuestas } from 'src/app/clases/encuestas/filtros';
import { Cadena } from 'src/app/clases/varios/cadena';

@Injectable({
  providedIn: 'root'
})
export class UtilidadesService {
  private loading: Array<HTMLIonLoadingElement>;
  private totalLoading: number = 0;

  constructor(public cfgApp: ConfigAppService,
              public miSesion: MiSesionService,
              public translate: TranslateService,
              public loadingCtrl: LoadingController,
              public toastCtrl: ToastController) 
  { 
    this.loading = new Array<HTMLIonLoadingElement>();
  }

  public static getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  public traduce(key: string) {
    return this.translate.instant(key);
  }

  public traduceTextoCompuesto(key: string, valor1: string, valor2?: string, valor3?: string) {
    return this.componTexto(this.translate.instant(key), valor1, valor2, valor3);
  }

  public componTexto(texto: string, valor1: string, valor2?: string, valor3?: string) {
    let salida = texto;
    if (valor1 != undefined) salida = texto.replace("#1", valor1);
    if (valor2 != undefined) salida = salida.replace("#2", valor2);
    if (valor3 != undefined) salida = salida.replace("#3", valor3);

    return salida;
  }  

  public muestraError(codigoError: string) {
    this.presentToast(this.traduce(codigoError));
  }

  public muestraErrorTextoCompuesto(key: string, valor1: string, valor2?: string, valor3?: string) {
    this.presentToast(this.componTexto(this.translate.instant(key), valor1, valor2, valor3));
  }

  async presentToast(mensaje: string, duracion = this.cfgApp.TOAST_DURATION_WARNING, posicion?: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: duracion,
      showCloseButton: false,
      position: 'top'
    });
    toast.present();
  }

  async presentLoading() {
    const loadingItem = await this.loadingCtrl.create({
      message: this.traduce("APP.PLEASE_WAIT")
    });
    loadingItem.present();
    this.loading.push(loadingItem);
    this.totalLoading++;
    if (this.totalLoading <= 0) {
      // Ya se ha ejecutado el cancel, por lo que debemos forzarlo.
      this.cancelLoading(false);
    }
  }

  public cancelLoading(decrementa?: boolean) {
    if (decrementa == undefined) decrementa = true;

    if (this.loading.length > 0) {
      const loading = this.loading.pop();
      loading.dismiss();
    }

    if (decrementa) {
      this.totalLoading--;
    }
  }

  public smartCancelLoading() {
    if (this.loading.length > 0) {
      const loading = this.loading.pop();
      loading.dismiss();
      
      this.totalLoading = this.loading.length;
    }
  }

  public getIdiomaPais() {    
    let pais = this.miSesion.idioma.pais;
    if (!pais) {
      // Para pruebas en local.
      if (this.miSesion.idioma.idioma == "es") {
        pais = "ES";
      } else {
        pais = "US";
      }
    }
    let idiomaPais = this.miSesion.idioma.idioma + "-" + pais;

    return idiomaPais;

  }

  public formatTextNumber(numeroTexto: string, conSigno = false, moneda = null, decimales = null) {
    let texto = "";
    if (numeroTexto == null) {
      // No hacemos nada para que quede vacío.
      // this.traduce(0, "APP.GUION_SIMPLE");
      // texto = this.getTextoTraducido(0);
    } else {
      let numero = new Number(numeroTexto);
      if (isNaN(numero.valueOf())) {
        let cadena = new Cadena();
        numero = new Number(cadena.replaceAll(numeroTexto, ',', '.'));
      }

      if (moneda) {
        if ((numero > -100) && (numero < 100)) {
          numero = new Number(numero.toFixed(5));
          texto = numero.toLocaleString(this.getIdiomaPais(), {style: 'currency', currency: moneda, maximumFractionDigits: 5});
        } else {
          numero = new Number(numero.toFixed(2));
          texto = numero.toLocaleString(this.getIdiomaPais(), {style: 'currency', currency: moneda, maximumFractionDigits: 2});
        }
      } else {
        if (!decimales) {
          if ((numero > -100) && (numero < 100)) {
            numero = new Number(numero.toFixed(5));
            texto = numero.toLocaleString(this.getIdiomaPais(), {maximumFractionDigits: 5});
          } else { 
            numero = new Number(numero.toFixed(2));
            texto = numero.toLocaleString(this.getIdiomaPais(), {maximumFractionDigits: 2});
          }
        } else {
          if ((numero > -10) && (numero < 10)) {
            numero = new Number(numero.toFixed(decimales));
            texto = numero.toLocaleString(this.getIdiomaPais(), {maximumFractionDigits: decimales});
          } else { 
            numero = new Number(numero.toFixed(2));
            texto = numero.toLocaleString(this.getIdiomaPais(), {maximumFractionDigits: 2});
          }          
        }

      }

      if (conSigno && (numero > 0)) {
        texto = this.traduce("SIMBOLOS.MAS") + texto;
      }
    }

    return texto;

  }

  public getSeparadorDecimal() {
    let numero = new Number(1.11);
    let texto = numero.toLocaleString(this.getIdiomaPais());

    return texto.substr(1, 1);
  }

  public getSeparadorMillares() {
    let numero = new Number(1000);
    let texto = numero.toLocaleString(this.getIdiomaPais());

    return texto.substr(1, 1);
  }

  public stringToNumber(texto: string) {
    let retorno = new Number(texto);    
    let textoConvertido = "";
    let separadorDecimal = this.getSeparadorDecimal();
    let separadorMillares = this.getSeparadorMillares();

    if (separadorDecimal != ".") {
      textoConvertido = texto.replace(separadorDecimal, ";");
      textoConvertido = textoConvertido.replace(separadorMillares, "");
      textoConvertido = textoConvertido.replace(";", ".");
      retorno = new Number(textoConvertido);
    }

    return retorno.valueOf();

  }

  


  public sustituye(cadena: string, sustituir: string, sustituirPor: string, aPartirDe = 1) {
    let salida = cadena;
    let posicion = 0;
    let indice = 0;

    let total = 0;
    while ((indice >= 0) && (total < aPartirDe)) {
      indice = salida.indexOf(sustituir, posicion);
      if (indice >= 0) {
        total++;
        if (total >= aPartirDe) {
          let reemplaza = salida.substring(0, indice + 1);
          let reemplazaPor = salida.substring(0, indice + 1 - sustituir.length) + sustituirPor;
          salida = salida.replace(reemplaza, reemplazaPor);
        }
        posicion = indice + 1;
      }
    }

    return salida;
  }

  public miIndexOf(cadena: string, valorBuscado: string[], desde: number) {
    let indice = -1;

    // Recuperamos el menor de los índices;
    for (let i = 0; i < valorBuscado.length; i++) {
      let indiceTmp = cadena.indexOf(valorBuscado[i], desde);
      if (indiceTmp >= 0) {
        if (indice < 0) {
          indice = indiceTmp;
        } else {
          if (indiceTmp < indice) {
            indice = indiceTmp;
          }
        }
      }
    }

    return indice;
  }

  public trunca(cadena: string, sustituir: string[], sustituirPor: string, aPartirDe = 1) {
    let salida = cadena;
    let posicion = 0;
    let indice = 0;

    let total = 0;
    while ((indice >= 0) && (total < aPartirDe)) {
      indice = this.miIndexOf(cadena, sustituir, posicion);
      
      if (indice >= 0) {
        total++;
        if (total >= aPartirDe) {
          salida = salida.substring(0, indice) + sustituirPor;
          total = aPartirDe; // Para finalizar.
        }
        posicion = indice + 1;
      }
    }

    return salida;
  }

  public getFechaDesdeAhora(fechaTexto: string) {
    let momento = moment(fechaTexto, "YYYY-MM-DD HH:mm:ss");
    let fecha = momento.fromNow();

    return fecha;    
  }

  public isValidEmail(mail: string) { 
    return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail); 
  }

  public validEmailPattern() {
    return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
  }

  public fabIconDirection() {
    return (document.documentElement.dir = "ltr") ? "end": "start";
  }

  public clone(objeto: any) {
    let objetoJSON = JSON.stringify(objeto);

    return JSON.parse(objetoJSON);
  }

  public getFiltroPorTipoDeConsulta(tipoDeConsulta: TiposDeConsulta, esGrupo: boolean = false) {
    let filtroLocalidado = null;

    switch (tipoDeConsulta) {
      case TiposDeConsulta.EN_DIRECTO:
        filtroLocalidado = this.miSesion.usuario.filtroEncuestasEnDirecto;
        break;
      case TiposDeConsulta.MIS_ENCUESTAS:
        if (esGrupo) {
          filtroLocalidado = this.miSesion.usuario.filtroMisGrupos;
        } else {
          filtroLocalidado = this.miSesion.usuario.filtroMisEncuestas;
        }
        break;
      case TiposDeConsulta.HISTORICO:
        filtroLocalidado = this.miSesion.usuario.filtroHistorico;
        break;
      case TiposDeConsulta.MARCADAS_INADECUADAS:
        filtroLocalidado = this.miSesion.usuario.filtroMaradasInadecuadas;
        break;
      case TiposDeConsulta.INADECUADAS:
        filtroLocalidado = this.miSesion.usuario.filtroInadecuadas;
        break;
      case TiposDeConsulta.ENCUESTAS_GRUPO:
        filtroLocalidado = this.miSesion.usuario.filtroEncuestasGrupo;
        if ((filtroLocalidado === null) || (filtroLocalidado === undefined)) {
          filtroLocalidado = new FiltrosEncuestas();
          this.miSesion.usuario.filtroEncuestasGrupo = filtroLocalidado;
        }
        break;
    }

    return filtroLocalidado;
  }

  public getMesesDescripcionCorta() {
    let salida =  Array(this.traduce('APP.MES_C.1'), this.traduce('APP.MES_C.2'), this.traduce('APP.MES_C.3'), 
                        this.traduce('APP.MES_C.4'), this.traduce('APP.MES_C.5'), this.traduce('APP.MES_C.6'), 
                        this.traduce('APP.MES_C.7'), this.traduce('APP.MES_C.8'), this.traduce('APP.MES_C.9'), 
                        this.traduce('APP.MES_C.10'), this.traduce('APP.MES_C.11'), this.traduce('APP.MES_C.12'));

    return salida;
  }

  public getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public log(texto: string) {
    console.log(moment().format("x") + " >>> Atenea: " + texto);
  }

  public esLaVersionWeb() {
    let retorno = false;

    if (this.miSesion.version.packageName == "") retorno = true;

    return retorno;
  }

}
