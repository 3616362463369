import { PagosJC } from '../varios/pagosJC';
import { FiltrosEncuestas } from '../encuestas/filtros'

export class DatosUsuario {
  idUsuario: number;
  admin: boolean;
  alias: string = "";
  avatar: string = "";
  precio: string = "1.99";
  monedaPago: string = "EUR";
  pagoObligado: boolean;
  encuestasGratis: number;
  gruposGratis: number;
  publicidadBanner: boolean;
  publicidadInterstitial: boolean;
  publicidadVideo: boolean;
  publicidadTestingMode: boolean;
  fechaUltimaContribucion: string;
  fechaUltimoPago: string;
  fechaInicioPublicidad: string;
  fechaRestauraInicioPublicidad: string;
  pagosPaypalDisponibles: PagosJC[] = new Array<PagosJC>();
  respuestasPaypalDisponibles: any[] = new Array<any>();
  activarPagoPaypal: boolean;
  activarTarjetaPaypal: boolean;
  activarPagoPorStoreEncuesta: boolean;
  activarPagoPorStoreGrupo: boolean;
  productIDContribucion: string;
  productIDCandidatura: string;
  productIDVotacion: string;
  productIDGrupo: string;
  productIDEncuesta: string;
  productIDPromocionEncuesta: string;
  productIDDescargaPDF: string;
  idImagenUsuario: string;
  fechaImagenUsuario: string;
  enviaContactos: boolean;
  pushIOS: boolean; // Indica si los push de IOS se manejan directamente en Apple.
  pushIOSsandbox: boolean;
  tutorial_es: string;
  tutorial_en: string;
  tutorial_exclusiones: string;
  videos: any;
  videosVistos: string[];
  segundosVerPublicidad: number;
  segundosEntreConsultas: number;
  segundosMinimoEntreConsultas: number;
  mensajePublicidadVisto: boolean;
  mesesSinPublicidad: number;
  pantallasDetalleSinPublicidad: number;
  pantallasEmpresasSinPublicidad: number;
  pantallasMercadosSinPublicidad: number;

  hayCambiosEnConfiguracion: boolean = false;

  filtroEncuestasEnDirecto: FiltrosEncuestas;
  filtroMisEncuestas: FiltrosEncuestas;
  filtroMisGrupos: FiltrosEncuestas;
  filtroHistorico: FiltrosEncuestas;
  filtroMaradasInadecuadas: FiltrosEncuestas;
  filtroEncuestasGrupo: FiltrosEncuestas;
  filtroInadecuadas: FiltrosEncuestas;
  filtroDescartes: FiltrosEncuestas;
  filtroNotas: FiltrosEncuestas;
  filtroRespuestasEscritas: FiltrosEncuestas;
  previsualizacion: boolean = false;
  ordenarTrasVotar: boolean = true;
  colapsarTrasVotar: boolean = true;
  misEncuestasAlInicio: boolean = false;
  enDirectoAlInicio: boolean = true;
  soloEncuestasDeMiPais: boolean = false;
  
  resultadosModoLista: boolean = true;
  resultadosModoGraficoPuntos: boolean = true;
  resultadosModoGraficoPuntos3D: boolean = true;
  resultadosModoGraficoVotos: boolean = true;
  resultadosColorHielo: boolean = false;
  votacionesColorHielo: boolean = false;

  fechaValoracionApp: string = null;

  pushEncuestasNotificaciones: Array<string> = null;

  soloPublicidadGoogleAutomatica: boolean = false;
  publicidadMixta: boolean = false;

  constructor() {
    this.filtroEncuestasEnDirecto = new FiltrosEncuestas();
    this.filtroMisEncuestas = new FiltrosEncuestas();
    this.filtroMisGrupos = new FiltrosEncuestas();
    this.filtroHistorico = new FiltrosEncuestas();
    this.filtroMaradasInadecuadas = new FiltrosEncuestas();
    this.filtroEncuestasGrupo = new FiltrosEncuestas();
    this.filtroInadecuadas = new FiltrosEncuestas();
    this.filtroDescartes = new FiltrosEncuestas();
    this.filtroNotas = new FiltrosEncuestas();
    this.filtroRespuestasEscritas = new FiltrosEncuestas();

    this.segundosEntreConsultas = 10;
  }  
}
