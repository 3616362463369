export class PagosJC {
    public amount: string = "";
    public currency: string = "";
    public shortDescription: string = "";
    public intent: string = "";

    public softDescriptor: string = "";
    public custom: string = "";
    public bnCode: string = "";
    

  
    constructor(amount: string, currency: string, shortDescription: string, intent: string) {
        this.amount = amount;
        this.currency = currency;
        this.shortDescription = shortDescription;
        this.intent = intent;

    }
  }
  