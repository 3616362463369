import { Injectable } from '@angular/core';

import { ConexionBase, RetornoSimple, ERROR_HASH_CODE, ERROR_HASH_DESC } from '../../clases/varios/conexion-base';

import { MiSesionService } from '../mi-sesion/mi-sesion.service';
import { HttpService } from '../http/http.service';
import { ConfigAppService } from '../config-app/config-app.service';
import { CifradorService } from '../cifrador/cifrador.service';


class MiPayPalClient {
  environment: string;
  pagos_jc_sdk_version: string;
  platform: string;
  product_name: string;
}

class MiPayPalSalida {
  create_time: string;
  id: string;
  intent: string;
  state: string;
}

export class MiPayPalResponse {
  client: MiPayPalClient;
  response: MiPayPalSalida;
  response_type: string;

  constructor() {
    this.client = new MiPayPalClient();
    this.response = new MiPayPalSalida();
  }
}



export class ContribuirEntrada {
  idSesion: string;
  versionActual: number;
  idioma: string;
  masterKey: string;
  hash: string;
  fechaContribucion: string;
  pagoConPayPal: boolean;
  datosPagoPayPal: any;
  respuestaPayPal: any;
}

@Injectable({
  providedIn: 'root'
})
export class ContribucionService extends ConexionBase {
  private urlRelativaContribuir = '/contribucion/contribucionApp.php';  // URL to web api
  public datosSalidaContribuir: RetornoSimple;

  constructor(private httpService: HttpService,
              public cfgApp: ConfigAppService,
              private cifrador: CifradorService) {
    super(cfgApp);
  }


  public postContribuir(entrada: ContribuirEntrada): Promise<RetornoSimple> {
    let miHash = this.generaHashConexion(JSON.stringify(entrada));
    entrada.hash = miHash.toString();

    return new Promise(resolve => {
      this.httpService.send(this.getURLAbsoluta(this.urlRelativaContribuir), entrada)
        .subscribe(response => {
            this.datosSalidaContribuir = <RetornoSimple> (response.data) ? JSON.parse(response.data): response.body;

            let hash = this.datosSalidaContribuir.retorno.hash;
            this.datosSalidaContribuir.retorno.hash = this.cifrador.getHashKey(false);
            if (!this.validaHashConexion(JSON.stringify(this.datosSalidaContribuir), hash)) {
              this.datosSalidaContribuir.retorno.codigo = ERROR_HASH_CODE.toString();
              this.datosSalidaContribuir.retorno.descripcion = ERROR_HASH_DESC.toString();
            }

            resolve(this.datosSalidaContribuir);
          }, err => {
            resolve(Promise.reject(err.message || err));
          }
        );
    });

  }


}