import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

@Injectable({
  providedIn: 'root'
})
export class ConfigAppService {
  // Aplicación.
  public APP_NAME = "Atenea";

  public APP_ID = "atenea"

  // Fechas.
  public FORMATO_FECHA_HORA_ION_VALUE = "YYYY-MM-DDTHH:mm";

  // Tiempos.
  public PLEASE_WAIT_DURATION = 3000;
  public PLEASE_WAIT_DURATION_CORTA = 1000;
  public INTERVALO_ENTRE_COTIZACIONES = 10000;

  // Imágenes.
  public IMAGE_QUALITY_VALUE = 20;         // Reducción al 20%.
  public IMAGE_MAX_SIZE = 1024 * 5;        // 5 Mb.

  public IMAGE_MAX_TARGET_WIDTH = 1000;
  public IMAGE_MAX_TARGET_HEIGHT = 1000;
  public IMAGE_SAVE_TO_PHOTO_ALBUM = true;
  public IMAGE_ALLOW_EDIT = true;

  // Avatares sin imagen.
  public MAX_ESTILOS_LETRA_CIRCULO = 9;

  // Edición de campos.
  public TITLE_MAX_LENGTH = 24;
  public TITLE_MAX_LENGTH_DE = 48;
  public DESCRIPTION_MAX_LENGTH = 512;
  public DESCRIPTION_ROWS = 6;

  public TOAST_DURATION_DEFAULT = 4000;
  public TOAST_DURATION_CONFIRMATION = 4000;
  public TOAST_DURATION_WARNING = this.TOAST_DURATION_DEFAULT;

  public TOAST_POSITION_DEFAULT = "top";
  public TOAST_POSITION_CONFIRMATION = this.TOAST_POSITION_DEFAULT;
  public TOAST_POSITION_WARNING = this.TOAST_POSITION_DEFAULT;
  public TOAST_POSITION_REGISTRATION = "middle";

  public VERSION_VACIA = -1;
  public VERSION_ACTUAL = 300;

  // PayPal.
  public PAGOS_JC_PRODUCTION_CLIENT_ID = "ARgKK0m5q_mJi_eplYf7U1cegIGW034qbhkJa5AtxY9VNnIprYLIZZsfTQDTKQYaXopfLXQhssjyqgvP";
  public PAGOS_JC_SANDBOX_CLIENT_ID = "Ac9vQObkstUDjFxI8Ch71kbxk3wifEKv3W5VCbTgPztKMTeO6jZD8lXqLG6uYtGCrcfA2q7CkSLBPTn6";
  public PAGOS_JC_CURRENCY = "EUR";
  public PAGOS_JC_DESCRIPTION = "va por tracucción";
  public PAGOS_JC_INTENT = "sale";  // Para pagos inmediatos.
  public PAGOS_JC_SOFT_DESCRIPTOR = "Atenea";

  // Datos internacionales.
  public PHONE_PREFIXS_COUNTRY: String[];
  public simDetectada: boolean;
  public simLecturaAutorizada: boolean;
  public datosSIMListos: Promise<void>;

  // API
  public HASH_KEY = "U2FsdGVkX1/F8qO6ok7EmDL/nE9awWqAPXvA2mOe2YE=";
  public RESPUESTA_CORRECTA = "00000";
  public SIM_DEVICE_ID_MOCK = "DEVICE_ID";
  public SIM_SERIAL_MOCK = "SERIAL";
  public VERSION_SO_MOCK = "VERSION_SO";

  // COTIZACIONES.
  public AGNOS_HACIA_ATRAS_COMPRA = -100;
  
  // JUEGOS.
  public DURACION_MAXIMA_DIAS_ENCUESTAS = 60;
  public DIAS_ENTRE_FASES_NO_HABITUALES = 0;
  public DIAS_ENTRE_FASES_CORRECCIONES = -1;
  public DIAS_ENTRE_FASES = 15;
  public DIAS_HASTA_SIGUIENTE_PAGO = 365;  // Antes a 30. Lo cambiamos porque esto sólo es válido mientras se ejecuta el siguiente Login.

  // PUSH.
  public FCM_SENDER_ID = "845094605188";
  public FCM_PUSH_SERVICE_URL = "http://push.api.phonegap.com/v1/push";

  // WEBS.
  public ATENEA_WEB = "https://www.ateneaapp.com/atenea-web/";
  public ATENEA_YOUTUBE_CHANEL = "https://www.youtube.com/channel/UC15F1jox2KI8B_Sh3PASdsA";
  public ATENEA_GOOGLE_STORE = "https://play.google.com/store/apps/details?id=com.atenea.app";
  // public ATENEA_APPLE_STORE = "https://appsto.re/es/8Bgvib.i";
  public ATENEA_APPLE_STORE = "https://itunes.apple.com/app/atenea/id1461667882";
  public ATENEA_APPLE_ID_MARKET = "id1461667882";
  public EASY_SANTA_APPLE_ID_MARKET = "id1213490940";

  public appEnPausa: boolean;

  constructor(public platform: Platform, public device: Device) {
    this.appEnPausa = false;

    this.platform.pause.subscribe(() => {
      this.appEnPausa = true;
    });

    this.platform.resume.subscribe(() => {
      this.appEnPausa = false;
    });    
  }



  getTitleMaxLength() {
    switch (navigator.language.split('-')[0]) {
      case 'de':
        return this.TITLE_MAX_LENGTH_DE;
      default:
        return this.TITLE_MAX_LENGTH;
    }
  }

  public getHashKey() {
    return this.HASH_KEY;
  }

  public dispositivoAnchoGrande() {
    // Finalmente consideramos ancho grande sólo al apaisado, ya que las tableteas quedan 
    // pequeñas para mostrar todos los campos contemplados.
    return (this.platform.isLandscape());
    
  }

  public dispositivoTipoTableta() {
    // Consideramos ancho grande a las tabletas o a los móviles que están en apaisado.
    let miPlataforma = JSON.stringify(this.platform.platforms());
    let tableta = (miPlataforma.indexOf("ablet") >= 0);
    let core = (miPlataforma.indexOf("core") >= 0);
    return (tableta || core);
  }

  public getDeviceId() {
    let deviceId = this.device.uuid;

    if (!deviceId) {
      // let numeros = /[0-9]/g;
      // deviceId = this.platform.platforms() + "." + this.getVersionSO().replace(numeros, ''); // Para no depender de la versión de un mismo S.0.
      deviceId = this.platform.platforms() + "." + this.getVersionSO();
    }

    return deviceId;
  }

  public getVersionSO() {
    let versionSO = "";

    if (this.device.manufacturer && (this.device.manufacturer.length > 0)) versionSO += this.device.manufacturer;
    versionSO += ";"
    if (this.device.model && (this.device.model.length > 0)) versionSO += this.device.model;
    versionSO += ";"
    if (this.device.platform && (this.device.platform.length > 0)) versionSO += this.device.platform;
    versionSO += ";"
    if (this.device.version && (this.device.version.length > 0)) versionSO += this.device.version;

    if (versionSO == ";;;") versionSO = null;

    return versionSO;
  }

  public platformSubTitulosEnMayusculas() {
    let retorno = false;

    if (this.platform.is("ios")) {
      retorno = true;
    }

    return retorno;
  }

}
