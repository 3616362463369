export class DatosIdioma {
  public DEFAULT_LANGUAGE = "en";

  idioma: string;                 // Idioma de 2: es.
  pais: string;                // Código ISO: ES.

  constructor() {
    this.idioma = "";
  }
}
