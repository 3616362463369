import {Md5} from 'ts-md5/dist/md5';

import { ConfigAppService } from '../../servicios/config-app/config-app.service';

export const RETORNO_RESPUESTA_CORRECTA: String = "00000";

export const ERROR_HASH_CODE: String = "E1003";
export const ERROR_HASH_DESC: String = "E1003";

export class Retorno {
  public codigo: string;
  public descripcion: string;
  public hash: string;
}

export class RetornoSimple {
  public retorno: Retorno;
}

export class ConexionBase {
  /* ESTA ES LA CONFIGURACIÓN PARA CONECTARSE DESDE LOCAL (PORTÁTIL) AL SERVIDOR myssgame.com
  "proxies": [
    {
      "path": "/atenea/json",
      "proxyUrl": "http://myssgame.com/atenea/json", <<======
      "prueba": "http://localhost/atenea/json"

    }
    private dominio = 'http://localhost:8100/atenea/json/'; // Para ejecutar en LOCAL, ya sea para conectar contra localHOST como para myssgame.com
    private dominio = 'http://192.168.1.2:8100/atenea/json/'; // Para ejecutar en MÓVIL, en DEBUG con ionic run android -l -c (live restart) contra myssgame.com
  ] */


  // private dominio = 'http://localhost:8100/atenea/json/'; // Para ejecutar en LOCAL, ya sea para conectar contra localHOST como para myssgame.com
  // private dominio = 'http://localhost/atenea/json/'; // Para ejecutar en LOCAL, ya sea para conectar contra localHOST como para myssgame.com
  // private dominio = 'http://127.0.0.1/atenea/json/'; // Para ejecutar en LOCAL, ya sea para conectar contra localHOST como para myssgame.com
  private dominio = 'https://ateneaapp.com/atenea/json/'; // Paara ejecutar en MÓVIL, sin DEBUG (en este caso, quitar el proxy).

  constructor(private cfgApp2: ConfigAppService) {
  }

  getURLAbsoluta(urlRelativa: string) {
    return this.dominio + ((this.cfgApp2.VERSION_ACTUAL <= 999) ?
                              ("00" + this.cfgApp2.VERSION_ACTUAL).slice(-3) : this.cfgApp2.VERSION_ACTUAL) + urlRelativa;
  }

  generaHashConexion(dato: string) {
    let hash_cifrada = Md5.hashStr(dato);

    return hash_cifrada;
  }

  validaHashConexion(dato: string, hash: string) {
    let hashLocal = Md5.hashStr(dato);

    // console.log("atenea - HASH de salida: " + hashLocal);

    return hash == hashLocal;
  }

}