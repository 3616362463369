import { Component, OnInit, AfterViewInit, Input  } from '@angular/core';

import { UtilidadesService } from '../../../servicios/utils/utilidades.service';
import { PublicidadService } from '../../../servicios/publicidad/publicidad.service';
import { MiSesionService } from '../../../servicios/mi-sesion/mi-sesion.service';

@Component({
  selector: 'app-publicidad',
  templateUrl: './publicidad.component.html',
  styleUrls: ['./publicidad.component.scss']
})


export class PublicidadComponent implements OnInit, AfterViewInit  {
  @Input() tipo: number = 1;

  private publicidadInicializada = false;
  private publicidadVisible = false;

  constructor(public utilidades: UtilidadesService,
              public miSesion: MiSesionService,
              public publicidadService: PublicidadService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.publicidadInicializada = true;
  }

  private lanzaPublicidad() {
    setTimeout(()=>{
      try{
        (adsbygoogle = window.adsbygoogle || []).push({});
      }catch(e){
        console.error("adsbygoogle local error (ngAfterView)");
      }
    },500);    
  }

  public puedoMostrarPublicidad(tipo: number) {
    let retorno = false;

    if (!this.miSesion.usuario.soloPublicidadGoogleAutomatica && 
        (this.utilidades.esLaVersionWeb() || this.miSesion.usuario.publicidadMixta) && 
        this.publicidadService.hayQuePonerPublicidad()) {
      if (this.tipo == tipo) retorno = true;

      if (retorno && this.publicidadInicializada && !this.publicidadVisible) {
        this.publicidadVisible = true;
        this.lanzaPublicidad();
      }
    }

    return retorno;
  }

}
