export enum RegistroStatus {
  NO_REGISTRADO = 0,
  REGISTRADO = 1,
  REGISTRO_MODIFICADO = 2,
  PENDIENTE  = 3
}

export class DatosRegistro {
  estadoRegistro: RegistroStatus;

  estadoRegistroPush: RegistroStatus;   // Estado del regitro Push.
  registrationCode: string;             // Código de registro del dispositivo en Google Cloud Messaging.

  // Claves propias de la app.
  masterKey: string;
  secondaryKey: string;
  dinamicKey: string;
  registrationKey: string;    // Clave enviada por Push para confirmar el teléfono del usuario.
  fechaAlta: string;

  // Cuenta.
  estadoCuenta: RegistroStatus;
  cuenta: string;

  constructor() {
    this.estadoRegistro = RegistroStatus.NO_REGISTRADO;
    this.estadoRegistroPush = RegistroStatus.NO_REGISTRADO;
    this.registrationCode = '';

    this.estadoCuenta = RegistroStatus.NO_REGISTRADO;
    this.cuenta = '';
  }
}
