import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

import { MiSesionService } from '../../../servicios/mi-sesion/mi-sesion.service';
import { PublicidadService } from 'src/app/servicios/publicidad/publicidad.service';

@Component({
  selector: 'app-detalle-imagen',
  templateUrl: './detalle-imagen.page.html',
  styleUrls: ['./detalle-imagen.page.scss'],
})
export class DetalleImagenPage implements OnInit {
  public imagen: string;
  public titulo: string;

  public subscription: any = null;

  constructor(private ga: GoogleAnalytics,
              private publicidad: PublicidadService,
              public modalController: ModalController,
              public platform: Platform,
              public miSesion: MiSesionService) { }

  ngOnInit() {
  }

  public ionViewWillEnter() {
    this.ga.trackView('detalle-imagen');
    this.publicidad.gestionaPublicidad();

    this.titulo = this.miSesion.imagenDetalleTitulo;
    this.imagen = this.miSesion.imagenDetalleImagen;

    // Para que el backbutton no haga un back en la ventana de abajo y pueda cerrar incluso la app.
    this.subscription = this.platform.backButton.subscribe(()=>{
      this.dismiss();
    })

  }

  public ionViewDidLeave() {
    this.subscription.unsubscribe();
  }

  public dismiss() {
    this.miSesion.imagenDetalleTitulo = null;
    this.miSesion.imagenDetalleImagen = null;

    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
