import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { TranslateService } from '@ngx-translate/core';

import { DatosRegistro, RegistroStatus } from '../../clases/sesion/datos-registro';
import { DatosUsuario } from '../../clases/sesion/datos-usuario';
import { DatosIdioma } from '../../clases/sesion/datos-idioma';
import { DatosVersion } from '../../clases/sesion/datos-version';
import { CuentaUsuario } from '../../clases/sesion/cuenta-usuario';

import { Encuesta, Pregunta, Seccion } from 'src/app/clases/encuestas/encuesta';
import { Grupo } from 'src/app/clases/grupos/grupo';
import { FiltrosEncuestas, TiposDeConsulta } from 'src/app/clases/encuestas/filtros';

export const STORAGE_KEY_SESION_REGISTRO = "ATENEA.SESION.REGISTRO";
export const STORAGE_KEY_SESION_USUARIO = "ATENEA.SESION.USUARIO";
export const STORAGE_KEY_SESION_IDIOMA = "ATENEA.SESION.IDIOMA";
export const STORAGE_KEY_SESION_VERSION = "ATENEA.SESION.VERSION";

export const STORAGE_KEY_SESION_CUENTAS = "ATENEA.SESION.CUENTAS";


@Injectable({
  providedIn: 'root'
})
export class MiSesionService {
  public registro = new DatosRegistro();
  public usuario = new DatosUsuario();
  public idioma = new DatosIdioma();
  public version = new DatosVersion();

  public cuentas = new Array<CuentaUsuario>();

  public datosRegistroListos: Promise<DatosRegistro>;
  public datosUsuarioListos: Promise<DatosUsuario>;
  public datosIdiomaListos: Promise<DatosIdioma>;
  public datosVersionListos: Promise<DatosVersion>;
  public datosCuentasListos: Promise<Array<CuentaUsuario>>;

  public idSesion: string;
  public tipoDeConsultaActual: TiposDeConsulta;

  public modificacion: boolean = false;
  public clonacion: boolean = false;

  public encuestaActual: Encuesta;
  public seccionActual: Seccion;
  public preguntaActual: Pregunta;
  public encuestaNueva: Encuesta;
  public encuestaDetalle: Encuesta;
  public paginaVueltaAltaEncuesta: string;
  public paginaVueltaAltaCandidaturas: string;
  public recargarMisEncuestas: boolean = true;

  public filtroActual: FiltrosEncuestas = null;
  public paginaVueltaInvitaciones: string;  
  public paginaVueltaGenerica: string;

  public grupoActual: Grupo;
  public grupoNuevo: Grupo;
  public grupoDetalle: Grupo;
  public paginaVueltaAltaGrupo: string; 
  public recargarMisGrupos: boolean = true;

  public imagenDetalleTitulo: string = null;
  public imagenDetalleImagen: string = null;

  constructor (private storage: Storage, 
                private appVersion: AppVersion, 
                private  translate: TranslateService) {

  }

  creaSesionMockNOVALE() {
    this.usuario.idUsuario = 1;
    this.usuario.alias = "Juan Carlos";

    this.storage.set(STORAGE_KEY_SESION_USUARIO, JSON.stringify(this.usuario));
    this.storage.set(STORAGE_KEY_SESION_IDIOMA, JSON.stringify(this.idioma));
  }

  public recuperaDatosUsuario() {
    return this.usuario;
  }


  public recuperaDatosSesion() {
      this.datosRegistroListos = this.storage.get(STORAGE_KEY_SESION_REGISTRO).then(res => this.registro = JSON.parse(res) as DatosRegistro).catch(this.handleErrorRegistro);
      this.datosUsuarioListos = this.storage.get(STORAGE_KEY_SESION_USUARIO).then(
        res => this.usuario = JSON.parse(res) as DatosUsuario).catch(
          this.handleErrorUsuario
      );
      this.datosIdiomaListos = this.storage.get(STORAGE_KEY_SESION_IDIOMA).then(res => this.idioma = JSON.parse(res) as DatosIdioma).catch(this.handleErrorIdioma);
      this.datosVersionListos = this.storage.get(STORAGE_KEY_SESION_VERSION).then(res => this.version = JSON.parse(res) as DatosVersion).catch(this.handleErrorVersion);
      this.datosCuentasListos = this.storage.get(STORAGE_KEY_SESION_CUENTAS).then(res => this.cuentas = JSON.parse(res) as Array<CuentaUsuario>).catch(this.handleErrorCuentas);
  }

  public pintaDatosSesion() {
    console.log(this.registro);
    console.log(this.usuario);
    console.log(this.idioma);
    console.log(this.version);
    console.log(this.cuentas);
  }

  public guardaDatosSesion(porCambioConfiguracion?: boolean) {
    if ((porCambioConfiguracion != undefined) && (porCambioConfiguracion != null) && porCambioConfiguracion) {
      this.usuario.hayCambiosEnConfiguracion = true;
    }
    
    this.storage.set(STORAGE_KEY_SESION_REGISTRO, JSON.stringify(this.registro));
    this.storage.set(STORAGE_KEY_SESION_USUARIO, JSON.stringify(this.usuario));
    this.storage.set(STORAGE_KEY_SESION_IDIOMA, JSON.stringify(this.idioma));
    this.storage.set(STORAGE_KEY_SESION_VERSION, JSON.stringify(this.version));
    this.storage.set(STORAGE_KEY_SESION_CUENTAS, JSON.stringify(this.cuentas));
  }

  public inicializaCuentasUsuario() {
    if (this.registro) {
      if ((this.registro.estadoRegistro == RegistroStatus.REGISTRADO) ||
          (this.registro.estadoRegistro == RegistroStatus.REGISTRO_MODIFICADO)) {
        if ((this.cuentas == null) || (this.cuentas == undefined)) {
          this.cuentas = new Array<CuentaUsuario>();
        }

        if (this.cuentas.length == 0) {
          let cuenta = new CuentaUsuario();
          cuenta.datosUsuario = this.usuario;
          cuenta.datosRegistro = this.registro;
          cuenta.datosVersion = this.version;
          cuenta.datosIdioma = this.idioma;
          this.cuentas.push(cuenta);

          this.guardaDatosSesion();          
        }
      }
    }

  }

  public esLaCuentaActiva(cuenta: CuentaUsuario) {
    let retorno = false;

    if (cuenta) {
      if (cuenta.datosRegistro.cuenta == this.registro.cuenta) {
        retorno = true;
      }
    }

    return retorno;
  }

  public borraCuenta(cuenta: CuentaUsuario) {
    let indice = this.cuentas.findIndex(cta => ((cta.datosRegistro.cuenta == cuenta.datosRegistro.cuenta)));    
    if ((indice != null) && (indice >= 0)) {
      this.cuentas.splice(indice, 1);   

      if (this.cuentas.length > 0) {
        this.activaCuenta(this.cuentas[0]);
      } else {
        this.limpiaDatosSesion();
      }
    } 

  }

  public activaCuenta(cuenta: CuentaUsuario) {
    let retorno = false;
    let cuentaLocalizada = new CuentaUsuario();
    if (cuenta) {
      cuentaLocalizada = this.cuentas.find(cta => cta.datosRegistro.cuenta == cuenta.datosRegistro.cuenta);
    } else {
      if (this.cuentas.length > 0) {
        cuentaLocalizada = this.cuentas[0];
      }
    }

    if (cuentaLocalizada) {
      this.usuario = cuentaLocalizada.datosUsuario;
      this.registro = cuentaLocalizada.datosRegistro;
      this.version = cuentaLocalizada.datosVersion;
      this.idioma = cuentaLocalizada.datosIdioma;
      this.guardaDatosSesion();

      this.translate.use(this.idioma.idioma);
      retorno = true;

    }

    return retorno;
  }

  public creaCuentaConDatosSesion() {
    let cuentaLocalizada = new CuentaUsuario();

    if (this.cuentas) {
      cuentaLocalizada = this.cuentas.find(cta => cta.datosRegistro.cuenta == this.registro.cuenta);
    } else {
      cuentaLocalizada = null;
      this.cuentas = new Array<CuentaUsuario>();
    }

    if (!cuentaLocalizada) {
      cuentaLocalizada = new CuentaUsuario();
      this.cuentas.push(cuentaLocalizada);
    }

    cuentaLocalizada.datosUsuario = this.usuario;
    cuentaLocalizada.datosRegistro = this.registro;
    cuentaLocalizada.datosVersion = this.version;
    cuentaLocalizada.datosIdioma = this.idioma;

    this.guardaDatosSesion();

  }

  public limpiaDatosSesion(soloCuenta: boolean = false) {
    this.storage.set(STORAGE_KEY_SESION_REGISTRO, '');
    this.storage.set(STORAGE_KEY_SESION_USUARIO, '');
    this.storage.set(STORAGE_KEY_SESION_IDIOMA, '');
    this.storage.set(STORAGE_KEY_SESION_VERSION, '');

    // Terminamos borrando todo, todo, todo. (también listas locales de encuestas, etc., etc.)
    if (!soloCuenta) {
      this.storage.clear();
    }

    // Guardamos los datos de la versión porque esta información debe
    // permanecer de una versión a otra (registrosREalizados).
    this.storage.set(STORAGE_KEY_SESION_VERSION, JSON.stringify(this.version));

    // Inicializamos los datos del usuario.
    this.inicializaMiSesion();
    
    
  }

  public inicializaMiSesion() {
    this.usuario = new DatosUsuario();
    this.registro = new DatosRegistro();
    this.version = new DatosVersion();
    this.idioma = new DatosIdioma();

    this.cargaIdioma();

    let promise1 = this.appVersion.getAppName();
    let promise2 = this.appVersion.getPackageName();
    let promise3 = this.appVersion.getVersionCode();
    let promise4 = this.appVersion.getVersionNumber();

    promise1.then(appName => {
      promise2.then(packageName => {
        promise3.then(versionCode => {
          promise4.then(versionNumber => {
            if (appName) {}
            this.version.packageName = packageName;
            this.version.versionCode = versionCode.toString();
            this.version.versionNumber = versionNumber;
            this.guardaDatosSesion();
          })
        })
      })
    });
  }

  public actualizaVersion(versionVacia: number, versionActual: number) {
    if (!this.version.versionAnterior) {
      this.version.versionAnterior = versionVacia;
    }
    if (!this.version.versionActual) {
      this.version.versionActual = versionActual;
    } else {
      if (this.version.versionActual != versionActual) {
          this.version.versionAnterior = this.version.versionActual;
          this.version.versionActual = versionActual;
      }
    }

    this.version.registrosRealizados = this.version.registrosRealizados + 1;
  }

  cargaIdioma() {
    if (!this.idioma) this.idioma = new DatosIdioma();

    // Idioma del dispositivo.
    let userCountry = navigator.language.split('-')[1];
    let userLang = navigator.language.split('-')[0];
    // userLang = /(ar|bn|da|de|el|en|es|fi|fr|he|hi|id|it|ja|ko|nl|no|pa|pl|pt|ro|ru|sv|tr|zh)/gi.test(userLang) ? userLang : 'en';
    // userLang = /(de|en|es|fr|it|pt|zh)/gi.test(userLang) ? userLang : 'en';
    userLang = /(ar|bn|da|de|el|en|es|fi|fr|he|hi|id|it|ja|ko|nl|no|pa|pl|pt|ro|ru|sv|tr|zh)/gi.test(userLang) ? userLang : 'en';

    // Pais (como no leemos la SIM, lo sacamos del navegador).
    if (!this.idioma.pais || (this.idioma.pais == "")) {
      this.idioma.pais = userCountry;
      if (!this.idioma.pais || (this.idioma.pais == "")) {
        this.idioma.pais = "ES"; // por poner alguono.
      }
    }

    // Idioma por defecto.
    if (this.idioma) {
      this.translate.setDefaultLang(this.idioma.DEFAULT_LANGUAGE);
    } else {
      this.translate.setDefaultLang('en');
    }

    // Idioma.
    if (this.idioma.idioma != '') {
      this.translate.use(this.idioma.idioma);
      userLang = this.idioma.idioma;
    } else {
      this.translate.use(userLang);
    }

    if (this.idioma.idioma == '') {
      this.idioma.idioma = userLang;
    }

    // Adecuamos la dirección del texto (en el mtto de perfil también está).
    let languageDir = /(ar|fa|he|ur)/gi.test(userLang) ? "rtl" : "ltr";
    if (languageDir == "rtl") { 
      // this.platform.setDir("rtl", true);
      document.documentElement.dir = "rtl";
    } else {
      // this.platform.setDir("ltr", true);
      document.documentElement.dir = "ltr";
    }

    return userLang;
  }      

  public inicializaIdioma(trataEspeciales: boolean = false) {
    // Inicializamos el momento al idioma del usuario.
    try {
      let momentEspecial = /(ar|bn|fa|hi|pa)/gi.test(this.idioma.idioma) ? true : false;
      if (trataEspeciales && momentEspecial) {
        moment.locale("es"); // para forzar el dd/mmm/aaaa, ya que el calendario de ionic no funciona bien con el formato puro del idioma.
      } else {
        // moment.locale(this.idioma.idioma);
        moment.locale(navigator.language);
      }
      
    } catch (e) {
      try {
        moment.locale(navigator.language);
      } catch (e) {
        moment.locale("es-ES");
        console.log(this.constructor.name + " moment.locale " + navigator.language, e);
      }
    }    
  }

  private handleErrorRegistro(error: any) {
    console.error('Atenea::Registro inexistente en storage.', error);
    return Promise.resolve(new DatosRegistro());
  }

  private handleErrorIdioma(error: any) {
    console.error('Atenea::Idioma inexistente en storage.', error);
    return Promise.resolve(new DatosIdioma());
  }

  private handleErrorUsuario(error: any) {
    console.error('Atenea::Usuario inexistente en storage.', error);
    return Promise.resolve(new DatosUsuario());
  }

  private handleErrorVersion(error: any) {
    console.error('Atenea::Version inexistente en storage.', error);
    return Promise.resolve(new DatosVersion());
  }

  private handleErrorCuentas(error: any) {
    console.error('Atenea::Cuentas inexistente en storage.', error);
    return Promise.resolve(new Array<CuentaUsuario>());
  }
      

}
