import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './paginas/home/home.module#HomePageModule' },
  { path: 'registro', loadChildren: './paginas/registro/registro.module#RegistroPageModule' },
  { path: 'registro-paso2', loadChildren: './paginas/registro-paso2/registro-paso2.module#RegistroPaso2PageModule' },
  { path: 'bienvenida', loadChildren: './paginas/bienvenido/bienvenido.module#BienvenidoPageModule' },
  { path: 'inicializacion', loadChildren: './paginas/inicializacion/inicializacion.module#InicializacionPageModule' },
  { path: 'perfil', loadChildren: './paginas/perfil/perfil.module#PerfilPageModule' },
  { path: 'alta-encuesta', loadChildren: './paginas/encuestas/alta-encuesta/alta-encuesta.module#AltaEncuestaPageModule' },
  { path: 'alta-encuesta-preguntas', loadChildren: './paginas/encuestas/alta-encuesta-preguntas/alta-encuesta-preguntas.module#AltaEncuestaPreguntasPageModule' },
  { path: 'en-directo', loadChildren: './paginas/encuestas/mis-encuestas/mis-encuestas.module#MisEncuestasPageModule' },
  { path: 'mis-encuestas', loadChildren: './paginas/encuestas/mis-encuestas/mis-encuestas.module#MisEncuestasPageModule' },
  { path: 'historico', loadChildren: './paginas/encuestas/mis-encuestas/mis-encuestas.module#MisEncuestasPageModule' },
  { path: 'marcadas-inadecuadas', loadChildren: './paginas/encuestas/mis-encuestas/mis-encuestas.module#MisEncuestasPageModule' },
  { path: 'inadecuadas', loadChildren: './paginas/encuestas/mis-encuestas/mis-encuestas.module#MisEncuestasPageModule' },
  { path: 'encuestas-grupo', loadChildren: './paginas/encuestas/mis-encuestas/mis-encuestas.module#MisEncuestasPageModule' },
  { path: 'invitaciones', loadChildren: './paginas/encuestas/invitaciones/invitaciones.module#InvitacionesPageModule' },
  { path: 'invitaciones-registro', loadChildren: './paginas/invitaciones-registro/invitaciones-registro.module#InvitacionesRegistroPageModule' },
  { path: 'alta-candidatura', loadChildren: './paginas/encuestas/alta-candidatura/alta-candidatura.module#AltaCandidaturaPageModule' },
  { path: 'descartes', loadChildren: './paginas/encuestas/descartes/descartes.module#DescartesPageModule' },
  { path: 'votaciones', loadChildren: './paginas/encuestas/votaciones/votaciones.module#VotacionesPageModule' },
  { path: 'invitaciones-correo', loadChildren: './paginas/encuestas/invitaciones-correo/invitaciones-correo.module#InvitacionesCorreoPageModule' },
  { path: 'preferencias', loadChildren: './paginas/preferencias/preferencias.module#PreferenciasPageModule' },
  { path: 'resultados-encuesta', loadChildren: './paginas/encuestas/resultados-encuesta/resultados-encuesta.module#ResultadosEncuestaPageModule' },
  { path: 'notas-examen', loadChildren: './paginas/encuestas/notas-examen/notas-examen.module#NotasExamenPageModule' },
  { path: 'respuestas-escritas', loadChildren: './paginas/encuestas/respuestas-escritas/respuestas-escritas.module#RespuestasEscritasPageModule' },
  { path: 'mis-grupos', loadChildren: './paginas/grupos/mis-grupos/mis-grupos.module#MisGruposPageModule' },
  { path: 'alta-grupo', loadChildren: './paginas/grupos/alta-grupo/alta-grupo.module#AltaGrupoPageModule' },
  { path: 'invitaciones-grupo', loadChildren: './paginas/grupos/invitaciones-grupo/invitaciones-grupo.module#InvitacionesGrupoPageModule' },
  { path: 'invitaciones-grupo-correo', loadChildren: './paginas/grupos/invitaciones-grupo-correo/invitaciones-grupo-correo.module#InvitacionesGrupoCorreoPageModule' },
  { path: 'cuentas', loadChildren: './paginas/cuentas/cuentas/cuentas.module#CuentasPageModule' },
  { path: 'publicidad', loadChildren: './paginas/publicidad/publicidad/publicidad.module#PublicidadPageModule' },
  { path: 'redes-sociales', loadChildren: './paginas/redes-sociales/redes-sociales/redes-sociales.module#RedesSocialesPageModule' },
  { path: 'detalle-imagen', loadChildren: './paginas/imagen/detalle-imagen/detalle-imagen.module#DetalleImagenPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {onSameUrlNavigation: "reload"})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
