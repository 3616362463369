import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

//import { AdsenseModule } from 'ng2-adsense';

// Componentes externos que realizan peticiones
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { MyMissingTranslationHandler } from '../../../clases/varios/my-missing-translation-handler';

// Configuración de traducción
import { customTranslateLoader } from '../../../app.module';
import { PublicidadComponent } from './publicidad.component';

@NgModule({
  declarations: [ PublicidadComponent ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // shown passing global defaults (optional)
    /*AdsenseModule.forRoot({
      adClient: 'ca-pub-4431837088441562',
      adSlot: 4288923206,
    }),*/
  ],
  providers: [
    { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler}
  ],
  exports: [
    PublicidadComponent, CommonModule, FormsModule, IonicModule
  ]
})
export class PublicidadModule { }
