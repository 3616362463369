import { Injectable } from '@angular/core';

import { Imagenes } from '../../clases/imagenes/imagenes';

import { ConfigAppService } from '../../servicios/config-app/config-app.service';

import { CifradorService } from '../../servicios/cifrador/cifrador.service';
import { HttpService }  from '../../servicios/http/http.service';

import { ConexionBase, ERROR_HASH_CODE, ERROR_HASH_DESC } from '../../clases/varios/conexion-base';


export const TIPO_CONSULTA_TODOS = "TODOS";
export const TIPO_CONSULTA_ENCUESTAS = "ENCUESTAS";
export const TIPO_CONSULTA_CONTACTOS = "CONTACTOS";
export const TIPO_CONSULTA_SECCIONES = "SECCIONES";
export const TIPO_CONSULTA_PREGUNTAS = "PREGUNTAS";
export const TIPO_CONSULTA_RESPUESTAS = "RESPUESTAS";

export class ImagenesEntrada {
  idSesion: string;
  versionActual: number;
  idioma: string;
  masterKey: string;
  identificadores: string[];
  tipoConsulta: string;
  hash: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImagenService extends ConexionBase {
  private urlRelativa = '/imagenes/consultaImagenesApp.php';  // URL to web api
  public datosSalidaImagenes: Imagenes;

  constructor(private httpService: HttpService,
              private cifrador: CifradorService,
              public cfgApp: ConfigAppService) {
    super(cfgApp);
  }

  public postConsultaImagenes(entrada: ImagenesEntrada): Promise<Imagenes> {
    let miHash = this.generaHashConexion(JSON.stringify(entrada));
    entrada.hash = miHash.toString();

    return new Promise(resolve => {
      this.httpService.send(this.getURLAbsoluta(this.urlRelativa), entrada)
        .subscribe(response => {
            this.datosSalidaImagenes = <Imagenes> (response.data) ? JSON.parse(response.data): response.body;

            let hash = this.datosSalidaImagenes.retorno.hash;
            this.datosSalidaImagenes.retorno.hash = this.cifrador.getHashKey(false);
            if (!this.validaHashConexion(JSON.stringify(this.datosSalidaImagenes), hash)) {
              this.datosSalidaImagenes.retorno.codigo = ERROR_HASH_CODE.toString();
              this.datosSalidaImagenes.retorno.descripcion = ERROR_HASH_DESC.toString();
            }

            resolve(this.datosSalidaImagenes);
          }, err => {
            resolve(Promise.reject(err.message || err));
          }
        );
    });

  }

}
