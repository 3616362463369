import * as moment from 'moment';
import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';

import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { DatosIdioma } from '../app/clases/sesion/datos-idioma';
import { DatosRegistro, RegistroStatus } from '../app/clases/sesion/datos-registro';
import { DatosUsuario } from '../app/clases/sesion/datos-usuario';
import { DatosVersion } from '../app/clases/sesion/datos-version';

import { PushService } from './servicios/push/push.service';
import { MiSesionService } from './servicios/mi-sesion/mi-sesion.service';
import { ExcepcionService } from './servicios/excepcion/excepcion.service';
import { PublicidadService } from './servicios/publicidad/publicidad.service';
import { LoginService } from './servicios/login/login.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'JUSTO_AHORA', url: '/en-directo', icon: 'timer', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'MIS_VOTACIONES', url: '/mis-encuestas', icon: 'archive', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },    
    { title: 'HISTORICO', url: '/historico', icon: 'calendar', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'GRUPOS', url: '/mis-grupos', icon: 'contract', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'MARCADAS_INADECUADAS', url: '/marcadas-inadecuadas', icon: 'heart-dislike', mustBeNotRegistered: false, mustBeRegistered: true, admin: true },
    { title: 'INADECUADAS', url: '/inadecuadas', icon: 'hand', mustBeNotRegistered: false, mustBeRegistered: true, admin: true }
  ];

  public socialPages = [
    // { title: 'FOROS', url: '/home', icon: 'chatboxes', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'REDES_SOCIALES', url: '/redes-sociales', icon: 'people', mustBeNotRegistered: false, mustBeRegistered: true, admin: false }
  ];  

  public accountPages = [
    { title: 'REGISTRO', url: '/registro', icon: 'aperture', mustBeNotRegistered: true, mustBeRegistered: false, admin: false },
    { title: 'PERFIL', url: '/perfil', icon: 'person', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'PREFERENCIAS', url: '/preferencias', icon: 'settings', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'INVITACIONES', url: '/invitaciones-registro', icon: 'key', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'PUBLICIDAD', url: '/publicidad', icon: 'megaphone', mustBeNotRegistered: false, mustBeRegistered: true, admin: false },
    { title: 'CUENTAS', url: '/cuentas', icon: 'lock', mustBeNotRegistered: false, mustBeRegistered: false, admin: false }
  ];  

  public aboutPages = [
    { title: 'BIENVENIDA', url: '/bienvenida', icon: 'information-circle', mustBeNotRegistered: false, mustBeRegistered: false, admin: false },
    // { title: 'TUTORIALES', url: '/list', icon: 'book', mustBeNotRegistered: false, mustBeRegistered: false, admin: false }
  ];    


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public navCtrl: NavController,
    private statusBar: StatusBar,
    private translate: TranslateService,
    public miSesion: MiSesionService,
    public excepcion: ExcepcionService,
    private loginService: LoginService,
    public ga: GoogleAnalytics,
    private pushService: PushService,
    private publicidad: PublicidadService,
    private appVersion: AppVersion
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Creamos una sesión fictícea (de momento).
      this.miSesion.recuperaDatosSesion();

      this.miSesion.datosIdiomaListos.then(res => {
        if (res){}; // Por que si no da error por no usarlo.
        this.miSesion.cargaIdioma();

        this.miSesion.datosRegistroListos.then(res => {
          if (res){}; // Por que si no da error por no usarlo.
          if (!this.miSesion.registro) this.miSesion.registro = new DatosRegistro();

          this.miSesion.datosVersionListos.then(res => {
            if (res){}; // Por que si no da error por no usarlo.
            if (!this.miSesion.version) this.miSesion.version = new DatosVersion();
            let promise1 = this.appVersion.getAppName();
            let promise2 = this.appVersion.getPackageName();
            let promise3 = this.appVersion.getVersionCode();
            let promise4 = this.appVersion.getVersionNumber();
        
            promise1.then(appName => {
              promise2.then(packageName => {
                promise3.then(versionCode => {
                  promise4.then(versionNumber => {
                    if (appName) {}
                    this.inicializaApp(packageName, versionCode.toString(), versionNumber);
                  })
                })
              })
            }).catch(err => {
              if ((err != "cordova_not_available") && (err != "Missing Command Error")) {
                this.excepcion.comunicaExcepcion(this.constructor.name, "Recupera datos versión", err);
              }

              this.inicializaApp("", "", "");
            });

            
          }).catch(()=>{
            if (!this.miSesion.version) this.miSesion.version = new DatosVersion();
          });
        }).catch(()=>{
          if (!this.miSesion.registro) this.miSesion.registro = new DatosRegistro();
        });
      }).catch(()=>{
        this.miSesion.cargaIdioma();
      });
    }).catch(res => {
      this.excepcion.comunicaExcepcion(this.constructor.name, "initializeApp", res);
    });

  }

  inicializaApp(packageName: string, versionCode: string, versionNumber: string) {
    this.miSesion.version.packageName = packageName;
    this.miSesion.version.versionCode = versionCode;
    this.miSesion.version.versionNumber = versionNumber;
    this.miSesion.guardaDatosSesion();

    this.miSesion.datosUsuarioListos.then(res => {
      if (res){}; // Por que si no da error por no usarlo.
      if (!this.miSesion.usuario) this.miSesion.usuario = new DatosUsuario();
      this.miSesion.usuario.mensajePublicidadVisto = false;

      this.miSesion.datosIdiomaListos.then(res => {
        if (res){}; // Por que si no da error por no usarlo.
        if (!this.miSesion.idioma) this.miSesion.idioma = new DatosIdioma();

        // Inicializamos el momento al idioma del usuario.
        this.miSesion.inicializaIdioma();

        // Inicializamos las notificaiones Push.
        this.pushService.inicializaPush();

        // Inicializamos el sistema de publicidad.
        this.publicidad.inicializaAdmobFree();

        // Google Analytics.
        this.ga.startTrackerWithId('UA-127127367-1')
        .then(() => {
          console.log('Google analytics is ready now');
          this.ga.setAllowIDFACollection(true);
        }).catch(e => console.log('Error starting GoogleAnalytics', e));        
               

        if ((this.miSesion.registro.estadoRegistro == RegistroStatus.REGISTRADO) ||
            (this.miSesion.registro.estadoRegistro == RegistroStatus.REGISTRO_MODIFICADO)) {
          this.loginService.ejecutaLogin();
        }

        this.statusBar.styleDefault();
        this.splashScreen.hide();              

      }).catch(()=>{
        if (!this.miSesion.idioma) this.miSesion.idioma = new DatosIdioma();
      });
    }).catch(()=>{
      if (!this.miSesion.usuario) this.miSesion.usuario = new DatosUsuario();
    });        
  }

  


  public puedoMostrarMenu(debeEstarSinRegistrar: boolean, debeEstarRegistrado: boolean, debeSerAdmin: boolean) {
    let condicion1 = false;
    let condicion2 = false;
    let condicion3 = false;

      if (debeEstarSinRegistrar) {
        condicion1 = !this.miSesion || !this.miSesion.registro || 
                      ((this.miSesion.registro.estadoRegistro != RegistroStatus.REGISTRADO) &&
                        (this.miSesion.registro.estadoRegistro != RegistroStatus.REGISTRO_MODIFICADO));
      } else {
        condicion1 = true;
      } 
    
      if (debeEstarRegistrado) {
        condicion2 = this.miSesion && this.miSesion.registro && 
                      ((this.miSesion.registro.estadoRegistro == RegistroStatus.REGISTRADO) ||
                        (this.miSesion.registro.estadoRegistro == RegistroStatus.REGISTRO_MODIFICADO));
      } else {
        condicion2 = true;
      } 

      if (debeSerAdmin) {
        condicion3 = this.miSesion && this.miSesion.usuario && this.miSesion.usuario.admin;
      } else {
        condicion3 = true;
      }


    return condicion1 && condicion2 && condicion3;
  }
  
}
