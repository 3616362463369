export enum TiposDeConsulta {
    EN_DIRECTO = 'D',
    MIS_ENCUESTAS = 'M',
    HISTORICO = 'H',
    MARCADAS_INADECUADAS = 'I',
    INADECUADAS = 'Y',
    ENCUESTAS_GRUPO = 'G'
  }
  
export class FiltrosEncuestas {
    public tipoDeConsulta: TiposDeConsulta;
    public filtroBusqueda: string;
    public soloDeMiPais: boolean = false;
    public idiomaConsulta: string;
  
    constructor() {
        this.filtroBusqueda = "";
        this.idiomaConsulta = "";
    }
  }
  