import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage';

import { MyMissingTranslationHandler } from '../app/clases/varios/my-missing-translation-handler';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler } from '@ngx-translate/core';

import { GoogleChartsModule } from 'angular-google-charts';

import { HTTP } from '@ionic-native/http/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Push } from '@ionic-native/push/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { AdMobFree } from '@ionic-native/admob-free/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
//import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Market } from '@ionic-native/market/ngx';

import { HttpErrorInterceptor } from './interceptors/http/http-interceptor.service';
import { UtilidadesService } from './servicios/utils/utilidades.service';

import { DetalleImagenPage } from './paginas/imagen/detalle-imagen/detalle-imagen.page';
import { DetalleImagenPageModule } from './paginas/imagen/detalle-imagen/detalle-imagen.module';

export function customTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent ],
  entryComponents: [DetalleImagenPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    GoogleChartsModule.forRoot(),

    DetalleImagenPageModule,
    
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (customTranslateLoader),
          deps: [HttpClient]
      }
    })      
  ],
  providers: [
    StatusBar,
    SplashScreen,
    HTTP,
    GoogleAnalytics,
    AppVersion,
    AdMobFree,
    Device,
    Camera,
    Push,
    Badge,
    SocialSharing,
    InAppPurchase,
    InAppBrowser,
    Market,
    // InAppPurchase2,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, deps: [UtilidadesService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
