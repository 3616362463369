export class DatosVersion {
  versionAnterior: number;
  versionActual: number;    // De la clase de configuración.

  packageName: string = "";
  versionCode: string = "";
  versionNumber: string = "";
  mensajeAccion: string = "";
  codigoAccion: string = "";

  registrosRealizados: number = 0;
  numActualizarVersionRecibidos: number = 0;

  constructor() {

  }
}
