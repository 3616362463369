import { Injectable } from '@angular/core';
import { Badge } from '@ionic-native/badge/ngx';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { AlertController } from '@ionic/angular';

import { ConfigAppService } from '../config-app/config-app.service';
import { ExcepcionService } from '../excepcion/excepcion.service';
import { MiSesionService } from '../mi-sesion/mi-sesion.service';
import { UtilidadesService } from '../utils/utilidades.service';
import { RegistroStatus } from '../../clases/sesion/datos-registro';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  public registroRealizado: boolean;
  public textoTraducido: string[] = ['', '', '', '', ''];

  constructor(
              private push: Push,
              private badge: Badge,
              private alertCtrl: AlertController,
              private cfgApp: ConfigAppService,
              private utilidades: UtilidadesService,
              private miSesion: MiSesionService,
              private excepcion: ExcepcionService) {
    this.registroRealizado = false;
  }


  inicializaPush() {
    try {
      this.push.hasPermission().then((res: any) => {
        if (res.enabled) {} // No hacemos nada, de momento.
        // 15.08.2017: No lo comprobamos porque en IOS siempre da false.

        const options: PushOptions = {
          android: {
            // JCARLOS: En esta versión ha desaparecido el senderID. Está en config.xml.
            senderID: this.cfgApp.FCM_SENDER_ID
          },
          ios: {
            //If using GCM for ios, project token number (12 digit) from https://console.developers.google.com

            // JCARLOS: En esta versión ha desaparecido el senderID. Está en config.xml.
            // senderID: ((this.miSesion.usuario.pushIOS) ? "": this.cfgApp.FCM_SENDER_ID) ,

            //If using GCM for ios
            fcmSandbox: this.miSesion.usuario.pushIOSsandbox,
            alert: true,
            badge: true,
            sound: true
          },
          windows: {
            pushServiceURL: this.cfgApp.FCM_PUSH_SERVICE_URL
          }
        };

        const pushObject: PushObject = this.push.init(options);

        pushObject.on('registration').subscribe((registration: any) =>  {
          // Guardamos el código de registro
          if (this.miSesion.registro.registrationCode != registration.registrationId) {
            this.miSesion.registro.registrationCode = registration.registrationId;
            this.miSesion.registro.estadoRegistroPush = RegistroStatus.REGISTRO_MODIFICADO;
            // NOTA IMPORTANTE: No se puede guardar aún la sesión porque estamos cargando sus datos, y se
            // puede corromper. Lo hacemos al acabar el Login.
          }

          this.registroRealizado = true;
        });

        pushObject.on('notification').subscribe((notification: any) =>  {
          // Vemos si se trata de una notificación de registro.
          if (notification.additionalData["atenea_type"] && (notification.additionalData["atenea_type"] == "registro")) {

          } else {
            // Otras notificaciones.


            // Sólo mostramos la alerta si estamos en primer plano. Si estamos en
            // segundo plano, no la mostramos porque la notificación se muestra en
            // la zona de notificaciones del móvil.
            if (notification.additionalData.foreground) {
              const confirm = this.alertCtrl.create({
                header: ((notification.title) ? notification.title: notification.additionalData["title"]),
                message: notification.message,
                buttons: [
                  { // De acuerdo
                    text: this.utilidades.traduce('APP.DE_ACUERDO'),
                    handler: () => {
                      // No hay nada que hacer.
                    }
                  }
                ]
              }).then (data => {data.present()});
              this.badge.clear();
            } else {
              if (this.miSesion.usuario.pushEncuestasNotificaciones == null) {
                this.miSesion.usuario.pushEncuestasNotificaciones = new Array<string>();
              }

              if (notification.additionalData["atenea_type"]) {
                this.miSesion.usuario.pushEncuestasNotificaciones.push(notification.message);
                if (notification.additionalData["atenea_id_encuesta"]) {
                  this.miSesion.usuario.pushEncuestasNotificaciones.push(notification.additionalData["atenea_id_encuesta"]);
                }
                this.miSesion.guardaDatosSesion();
              }

              this.badge.increase(1);
            }
          }
        });

        pushObject.on('error').subscribe((error: any) =>  {
          this.excepcion.comunicaExcepcion(this.constructor.name, "push.on error", error);
        });
    });
  } catch (e) {
    this.excepcion.comunicaExcepcion(this.constructor.name, "inicializaPush", e);
  }


    return this.registroRealizado;

  }

}
