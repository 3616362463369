import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { AdMobFree, AdMobFreeBannerConfig, AdMobFreeInterstitialConfig, AdMobFreeRewardVideoConfig } from '@ionic-native/admob-free/ngx';
import { Platform, Events } from '@ionic/angular';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

import { PagosJC} from '../../clases/varios/pagosJC';
import { TranslateService } from '@ngx-translate/core';

import { MiSesionService } from '../mi-sesion/mi-sesion.service';
import { ConfigAppService } from '../config-app/config-app.service';
import { ContribucionService, MiPayPalResponse, ContribuirEntrada } from '../contribucion/contribucion.service';
import { CifradorService } from '../cifrador/cifrador.service';
import { ExcepcionService } from '../excepcion/excepcion.service';
import { UtilidadesService } from '../../servicios/utils/utilidades.service';

const PANTALLAS_SIN_PUBLICIDAD = 2;

@Injectable({
  providedIn: 'root'
})
export class PublicidadService {

  private contadorMostrarPublicidad = 1;

  public registroRealizado: boolean;
  private admobFreeId: any;
  public bannerDisponible: Promise<any> = null;
  public interstitialDisponible: Promise<any> = null;
  public videoDisponible: Promise<any> = null;
  public fechaHoraUltimoClickValido: any;
  public tocaMostrarVideo: boolean;
  public bannerVisible: boolean;

  constructor(public platform: Platform,
              private admobFree: AdMobFree,
              public events: Events,
              public miSesion: MiSesionService,
              private cfgApp: ConfigAppService,
              private contribucionService: ContribucionService,
              private cifrador: CifradorService,
              public alertCtrl: AlertController,
              public toastCtrl: ToastController,
              public translate: TranslateService,
              public excepcion: ExcepcionService,
              public utilidades: UtilidadesService) {

    this.contadorMostrarPublicidad = 1;
    this.registroRealizado = false;
    this.fechaHoraUltimoClickValido = null;
  }


  public inicializaAdmobFree() {
    this.tocaMostrarVideo = true;
    this.bannerVisible = false;
    
    // select the right Ad Id according to platform
    if (this.platform.is('android')) {
      this.admobFreeId = { // for Android
          banner: 'ca-app-pub-4431837088441562/1467693131',
          interstitial: 'ca-app-pub-4431837088441562/5844129833',
          video: 'ca-app-pub-4431837088441562/5844129833'
      };
    } else if (this.platform.is('ios')) {
      this.admobFreeId = { // for ios
          banner: 'ca-app-pub-4431837088441562/9246998855',
          interstitial: 'ca-app-pub-4431837088441562/6620835510',
          video: 'ca-app-pub-4431837088441562/6620835510'
      };
    /*} else if (this.platform.is('windows')) {
      this.admobFreeId = { // for windows
          banner: 'ca-app-pub-4431837088441562/8133716485',
          interstitial: 'ca-app-pub-4431837088441562/8150028359',
          video: 'ca-app-pub-4431837088441562/8150028359'
      };*/
    } else {
      this.admobFreeId = { // for others
        banner: 'ca-app-pub-4431837088441562/1467693131',
        interstitial: 'ca-app-pub-4431837088441562/5844129833',
        video: 'ca-app-pub-4431837088441562/5844129833'
      };
    }

    const bannerConfig: AdMobFreeBannerConfig = {
      id: this.admobFreeId.banner,
      isTesting: this.miSesion.usuario.publicidadTestingMode,
      autoShow: false
    };

    
    const interstitialConfig: AdMobFreeInterstitialConfig = {
      id: this.admobFreeId.interstitial,
      isTesting: this.miSesion.usuario.publicidadTestingMode,
      autoShow: false
    };

    const rewardVideoConfig: AdMobFreeRewardVideoConfig = {
      id: this.admobFreeId.video,
      isTesting: this.miSesion.usuario.publicidadTestingMode,
      autoShow: false
    };

        
    this.admobFree.banner.config(bannerConfig);
    this.admobFree.interstitial.config(interstitialConfig);
    this.admobFree.rewardVideo.config(rewardVideoConfig);

    this.preparaBanner();
    
  }

  public gestionaPublicidad(mostrarPubli: boolean = true) {
    if (mostrarPubli) {
      if (!this.utilidades.esLaVersionWeb()) {
        this.preparaBanner()
        this.showBanner();
  
          if (this.tocaMostrarVideo && this.miSesion.usuario.publicidadVideo) {
            if (this.preparaVideo()) {
              this.showVideo();
            }
          } else {
            if (this.preparaInterstitial()) {
              this.showInterstitial();
            }
          }
        }
      }
  }

  public preparaBanner() {
      if (this.admobFree && this.admobFreeId && this.miSesion.usuario.publicidadBanner && 
          this.hayQuePonerPublicidad()) {
        this.bannerDisponible = this.admobFree.banner.prepare();
      }
  }

  public showBanner() {

      this.bannerVisible = true;

      if (this.admobFree && this.bannerDisponible && this.miSesion.usuario.publicidadBanner && 
          this.hayQuePonerPublicidad()) {
        this.bannerDisponible.then(() => {
          // admobFree.showBanner(positionMap[position.toLowerCase()]);
          this.admobFree.banner.show();
        }).catch((e) => {
          if (this.bannerDisponible) {
            // Si había publiciidad, la quitamos.
            this.hideBanner();
          }
          this.excepcion.comunicaExcepcion(this.constructor.name, "showBanner", e);
        });
      }

  }


  public preparaInterstitial() {
    let show = false;
    if (this.admobFree && this.admobFreeId && this.miSesion.usuario.publicidadInterstitial && 
        this.hayQuePonerPublicidad() && this.ponerPublicidadSegunPantalla2()) {
        if (!this.interstitialDisponible) {
          this.interstitialDisponible = this.admobFree.interstitial.prepare().catch((e) => {
            this.excepcion.comunicaExcepcion(this.constructor.name, "prepareInterstitial", e);
          });
        } else {
          show = true;
        }
    }

    return show;
  }

  public showInterstitial() {
    if (this.admobFree && this.interstitialDisponible && this.miSesion.usuario.publicidadInterstitial && 
        this.hayQuePonerPublicidad() && this.ponerPublicidadSegunPantalla2(true)) {
      this.interstitialDisponible.then(() => {
        this.admobFree.interstitial.show();
        this.tocaMostrarVideo = !this.tocaMostrarVideo;
        this.interstitialDisponible = null;
      }).catch((e) => {
        this.excepcion.comunicaExcepcion(this.constructor.name, "showInterstitial", e);
      });
    }
  }

  public preparaVideo() {
    let show = false;
    if (this.admobFree && this.admobFreeId && this.miSesion.usuario.publicidadVideo && 
        this.hayQuePonerPublicidad() && this.ponerPublicidadSegunPantalla2()) {
        if (!this.videoDisponible) {
          this.videoDisponible = this.admobFree.rewardVideo.prepare().catch((e) => {
            this.excepcion.comunicaExcepcion(this.constructor.name, "prepareRewardVideoAd", e);
          });
        } else {
          show = true;
        }
    }

    return show;
  }


    public showVideo() {
      if (this.admobFree && this.videoDisponible && this.miSesion.usuario.publicidadVideo && 
          this.hayQuePonerPublicidad() && this.ponerPublicidadSegunPantalla2(true)) {
        this.videoDisponible.then(() => {
          this.admobFree.rewardVideo.show();
          this.tocaMostrarVideo = !this.tocaMostrarVideo;
          this.videoDisponible = null;
        }).catch((e) => {
          this.excepcion.comunicaExcepcion(this.constructor.name, "showInterstitial", e);
        });
      }
    }

  public hideBanner() {
    this.bannerVisible = false;
    if (this.admobFree && this.miSesion.usuario.publicidadBanner) {
      this.admobFree.banner.hide();
      this.miSesion.usuario.publicidadBanner = false;
    }
  }


  public hayQuePonerPublicidad() {
    let retorno = false;
    let fechaComparacion = "";

    if (moment(this.miSesion.usuario.fechaRestauraInicioPublicidad, "YYYY-MM-DD HH:mm:ss").isAfter(this.miSesion.usuario.fechaInicioPublicidad)) {
      fechaComparacion = this.miSesion.usuario.fechaRestauraInicioPublicidad;
    } else {
      fechaComparacion = this.miSesion.usuario.fechaInicioPublicidad;
    }

    retorno = moment().isAfter(fechaComparacion);

    return retorno;
  }

  public ponerPublicidadSegunPantalla2(decrementa = false) {
    let retorno = false;

    // Unificamos el control de impresiones de publicidad.
    if (this.miSesion.usuario.pantallasMercadosSinPublicidad) {
      if (this.contadorMostrarPublicidad <= 1) {
        retorno = true;
        if (decrementa) this.contadorMostrarPublicidad = this.miSesion.usuario.pantallasMercadosSinPublicidad;
      } else {
        // Decrementamos porque no vamos a pasar por el show correspondiente.
        this.contadorMostrarPublicidad--;
      }
    } else {
      this.miSesion.usuario.pantallasMercadosSinPublicidad = PANTALLAS_SIN_PUBLICIDAD;
    }    

    return retorno;
  }






  public comunicarClick(adType: string, adNetwork:string) {
    // Preparamos el pago ficticio.
    let payment = new PagosJC("0.00",
                                    this.miSesion.usuario.monedaPago,
                                    "Atenea - Click",
                                    adType);
    payment.softDescriptor = this.cfgApp.PAGOS_JC_SOFT_DESCRIPTOR;
    payment.custom = this.miSesion.usuario.idUsuario + "." + "CPC" + "." +
                     moment().format() + "." + this.miSesion.registro.secondaryKey;

    payment.bnCode = "AteneaClick";

    // Preparamos la respuesta ficticia.
    let respuestaPayPal = new MiPayPalResponse();
    respuestaPayPal.client.environment = this.miSesion.version.packageName;
    respuestaPayPal.client.pagos_jc_sdk_version = this.miSesion.version.versionCode;
    respuestaPayPal.client.platform = JSON.stringify(this.platform.platforms());
    respuestaPayPal.client.product_name = "Clicks";
    respuestaPayPal.response.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
    respuestaPayPal.response.id = adNetwork;
    respuestaPayPal.response.intent = adType;
    if (this.miSesion.usuario.publicidadTestingMode) {
      respuestaPayPal.response_type = "sandbox";
      respuestaPayPal.response.state = "sandbox";
    } else {
      respuestaPayPal.response_type = "production";
      respuestaPayPal.response.state = "production";
    }

    this.clickOnLine(true, payment, respuestaPayPal);

  }

  public clickOnLine(pagoConPayPal: boolean, payment: any, respuestaPayPal:any) {

    let contribuirEntrada = new ContribuirEntrada();

    contribuirEntrada.idSesion = this.miSesion.idSesion;
    contribuirEntrada.masterKey = this.miSesion.registro.masterKey;
    contribuirEntrada.versionActual = this.miSesion.version.versionActual;
    contribuirEntrada.idioma = this.miSesion.idioma.idioma;
    contribuirEntrada.hash = this.cifrador.getHashKey();

    // La fecha del sorteo, cogemos la actual, convertida a local y luego a UTC.
    contribuirEntrada.fechaContribucion = moment.utc(moment().format()).format("YYYY-MM-DD HH:mm");

    contribuirEntrada.pagoConPayPal = pagoConPayPal;
    contribuirEntrada.datosPagoPayPal = payment;
    contribuirEntrada.respuestaPayPal = respuestaPayPal;

    // A continuación recuperamos los juegos online.
    this.contribucionService.postContribuir(contribuirEntrada).then(retorno => {
      if (retorno.retorno.codigo == this.cfgApp.RESPUESTA_CORRECTA) {
        // No hacemos nada.
      } else {
        // No hacemos nada.
      }
      }, err => {
        console.log(err);
        // No hacemos nada.
      }).catch(this.handleHTTPError);

  }

  private handleHTTPError(error: any) {
    if (this) this.excepcion.comunicaExcepcion(this.constructor.name, "handleHTTPErrorClick", error);

    return Promise.reject(error.message || error);
  }

}
