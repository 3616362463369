import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
 
import { UtilidadesService } from '../../servicios/utils/utilidades.service';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private utilidades: UtilidadesService) {
    
  }

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   return next.handle(request)
     .pipe(
       retry(0),
       catchError((error: HttpErrorResponse) => {
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `MENSAJE GENÉRICO CLIENT-SIDE: ${error.error.message}`;
         } else {
           // server-side error
           errorMessage = `MENSAJE GENÉRICO SERVER-SIDE Error Code: ${error.status}\nMessage: ${error.message}`;
         }
         this.utilidades.muestraError('ERROR_NUM.E1001');
         return throwError(errorMessage);
       })
     )
 }
}