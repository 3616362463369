import * as CryptoJS from 'crypto-js';

import { Injectable } from '@angular/core';

import { MiSesionService } from '../mi-sesion/mi-sesion.service';
import { ConfigAppService } from '../config-app/config-app.service';

@Injectable({
  providedIn: 'root'
})
export class CifradorService {

  private LOCAL_KEY_B64 = "oy6fco0pu7NuSSM9zjcfeLYadU2umm8kveDV42yU7vS76NrfHrsGsQwRJOkKDobX9mi1TSw8+b1YLZe86CczMA==";

  constructor(public miSesion: MiSesionService,
              private cfgApp: ConfigAppService) {
  }

  public generaClave() {
    let clave2 = this.encripta("XXXXXX");
    // let clave2 = clave2.toString(CryptoJS.enc.Utf8);
    alert(clave2);

    return clave2;
  }

  public encripta(texto: string, clave = this.LOCAL_KEY_B64) {
    let cifrado = CryptoJS.AES.encrypt(texto, clave);
    return cifrado;
  }

  public desencripta(textoCifradoB64: string, clave = this.LOCAL_KEY_B64) {
    let descifrado = CryptoJS.AES.decrypt(textoCifradoB64, clave);
    return descifrado.toString(CryptoJS.enc.Utf8);
  }

  public getHashKey(secondary = true) {
    if (secondary) {
      return this.desencripta(this.cfgApp.HASH_KEY) + this.miSesion.registro.secondaryKey;
    } else {
      return this.desencripta(this.cfgApp.HASH_KEY);
    }

  }
}